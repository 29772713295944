import React from 'react';

//components
// import JumbotronSale from './jumbotronSale';
import SlideMain from './slideMain';
import GetLoose from './../productsPage/getLoose';


const MainPage = (props) => {
  return (

    
      <>
      <SlideMain />
      <GetLoose/>
      </>
    
  );
}

export default MainPage;
